<template>
	<div>
		<v-fade-transition group hide-on-leave>
			<template v-if="loading">
				<div class="d-flex flex-wrap justify-start mb-2">
					<v-skeleton-loader type="chip" class="mx-1 my-1 rounded-xl" v-for="i in 12" :key="`loadingResult${i}`" />
				</div>
			</template>

			<template v-else-if="sectors.length">
				<div key="filteresResults" class="d-flex flex-wrap justify-start mb-2">
					<v-chip small v-for="sector in sectors" :key="`sector${sector.id}`" class="mx-1 my-1" close @click:close="callRemoveSector(sector)">
						{{ sector.name }}
					</v-chip>
				</div>
			</template>

			<template v-else>
				<div key="noItemsData" class="mx-auto mb-2">
					<div class="mt-2 mb-5 text-center">
						<v-icon size="128" role="img"> mdi-package-variant </v-icon>
						<br />
						<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
					</div>
					<h4 class="m-4 text-center">
						You don't have sectors added to your profile. You might want to add some to show your interests to recruiters.
					</h4>
				</div>
			</template>
		</v-fade-transition>

		<UserInterestsSectorsAdd :loading-parent="loading || loadingParent" />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'UserInterestsSectors',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
		}
	},
	components: {
		UserInterestsSectorsAdd: () => import('@/components/users/UserInterestsSectorsAdd.vue'),
	},
	computed: {
		...mapGetters({
			user: 'user/currentUser',
			sectors: 'cv/sectors',
		}),
	},
	created() {
		this.loading = true
		this.fetchSectors().then(() => {
			this.loading = false
		})
	},
	methods: {
		callRemoveSector(sector) {
			this.loading = true
			this.removeSector({
				sectorID: sector.id,
			}).then(() => {
				this.loading = false
			})
		},
		...mapActions('cv', ['fetchSectors', 'removeSector']),
	},
}
</script>
